import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterContainer from '../../components/centerContainer/CenterContainer';
import GoogleBusinessAutoComplete from '../../components/googleBusinessAutoComplete/GoogleBusinessAutoComplete';
import Spinner from '../../components/spinner/Spinner';
import { RemoveSalesBusiness, StoreBusiness, StoreSalesBusiness, StoreSalesTask } from '../../utils/StorageUtil';
import './GbpSelectScreen.css';
import { toast } from 'react-toastify';
import {
	CreateCampaign,
	CreateSalesCompany,
	FindGoogleProfileInformation,
	FindGoogleServiceAreaBusiness,
	GetCampaign,
	GetGooglePlaceReviews,
	GetUsers,
	RegisterUser,
	SerpFindGoogleProfileByPlaceId,
	UpdateCampaign
} from '../../Services';
import { Fragment } from 'react';
import { useStateValue } from '../../StateProvider';
import Select from 'react-select';
import PageHeadline from '../../components/pageHeadline/PageHeadline';
import ConfirmBusinessSelectionScreen from '../confirmBusinessSelectionScreen/ConfirmBusinessSelectionScreen';

const GbpSelectScreen = ({ approvedOrder = null }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ selectedBusiness, setSelectedBusiness ] = useState();
	const [ googlePlaceId, setGooglePlaceId ] = useState();
	const [ googleCid, setGoogleCid ] = useState();
	const [ lat, setLat ] = useState();
	const [ lng, setLng ] = useState();
	const [ isSalesProcess, setIsSalesProcess ] = useState(false);
	const [ { user }, dispatch ] = useStateValue();
	const [ searchParam, setSearchParam ] = useState('');
	const [ shareMapUrl, setShareMapUrl ] = useState();
	const [ manuallyCreate, setManuallyCreate ] = useState(false);
	const [ name, setName ] = useState();
	const navigate = useNavigate();
	const { campaignId } = useParams();
	const [ activeUsers, setActiveUsers ] = useState([]);
	const [ selectedUser, setSelectedUser ] = useState();
	const [ approvedOrderConfirmSelection, setApprovedOrderConfirmSelection ] = useState(false);
	const selectedUserRef = useRef();
	const daysInWeek = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

	useEffect(() => {
		if (window.location.pathname.indexOf('/sales') > -1) {
			setIsSalesProcess(true);
		}

		if (user && (user.isAdmin || user.isSalesUser)) {
			getActiveUsers();
		}
	}, []);

	useEffect(
		() => {
			if (selectedBusiness) {
				if (campaignId) {
					selectedBusiness.campaignId = campaignId;
				}
				if (approvedOrder && approvedOrder.id) {
					selectedBusiness.campaignId = approvedOrder.id;
					StoreBusiness(selectedBusiness);
					setApprovedOrderConfirmSelection(true);
				} else if (isSalesProcess) {
					StoreSalesBusiness(selectedBusiness);
					navigate('/sales/confirm-selection');
				} else {
					StoreBusiness(selectedBusiness);
					navigate('/confirm-selection');
				}
			}
		},
		[ selectedBusiness, isSalesProcess ]
	);

	useEffect(
		() => {
			if (window.mapReady) {
				setIsLoading(false);
			}
		},
		[ window.mapReady ]
	);

	useEffect(
		() => {
			if (activeUsers) {
				setSelectedUser(activeUsers[0]);
			}
		},
		[ activeUsers ]
	);

	const getActiveUsers = async () => {
		let response = await GetUsers();

		if (response && response.length) {
			let tempUsers = response.filter((x) => x.status === 'Active');

			let tempUsersForSelect = [
				{
					value : '0',
					label : 'Create New User'
				}
			];

			//sort users alphabetically
			tempUsers = tempUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));

			for (let i = 0; i < tempUsers.length; i++) {
				tempUsersForSelect.push({
					value : response[i].id,
					label : `${response[i].firstName} ${response[i].lastName} (${response[i].email})`
				});
			}

			setActiveUsers(tempUsersForSelect);
		}
	};

	const handlePlaceSelected = async (place) => {
		if (user) {
			if (user.isAdmin) {
				// let selectedUserValue = selectedUserRef.current ? selectedUserRef.current.getValue() : null;
				// place.userId =
				// 	selectedUserValue && selectedUserValue.length && selectedUserValue[0].value !== '0'
				// 		? selectedUserValue[0].value
				// 		: null;

				place.userId = selectedUser && selectedUser.value !== '0' ? selectedUser.value : '';
			} else {
				place.userId = user.id;
			}
		}

		if (approvedOrder && approvedOrder.userId) {
			place.userId = approvedOrder.userId;
		}

		// //get SerpApi reviews
		// place.serpApiReviews = await getSerpApiGooglePlaceReviews(place.place_id);

		setSelectedBusiness(place);
	};

	const handlePlaceIdSelect = async (e) => {
		e.preventDefault();

		if (searchParam) {
			setIsLoading(true);
			let data = { searchParam: searchParam };

			var response = await FindGoogleServiceAreaBusiness(data);

			if (response.success) {
				if (response.data) {
					let responseJson = JSON.parse(response.data);
					let tempLocation = {};

					if (responseJson && responseJson.knowledge_graph) {
						let placeId = responseJson.place_id || responseJson.knowledge_graph.place_id;

						if (!placeId) {
							toast.error('Service Area Business not found');
							setIsLoading(false);
							return;
						}

						let locationData = responseJson.knowledge_graph;

						if (user) {
							if (user.isAdmin) {
								// let selectedUserValue = selectedUserRef.current
								// 	? selectedUserRef.current.getValue()
								// 	: null;
								// tempLocation.userId =
								// 	selectedUserValue && selectedUserValue.length && selectedUserValue[0].value !== '0'
								// 		? selectedUserValue[0].value
								// 		: null;

								tempLocation.userId =
									selectedUser && selectedUser.value !== '0' ? selectedUser.value : '';
							} else {
								tempLocation.userId = user.id;
							}
						}

						if (approvedOrder && approvedOrder.userId) {
							tempLocation.userId = approvedOrder.userId;
						}

						tempLocation.place_id = placeId;
						tempLocation.name = responseJson.title || locationData.title;
						tempLocation.website = responseJson.website || locationData.website;
						tempLocation.formatted_phone_number = responseJson.phone || locationData.phone;

						//try to find cid
						let ludocidIndex = response.data.indexOf('ludocid=');
						if (ludocidIndex > -1) {
							tempLocation.cid = response.data.substring(
								ludocidIndex + 8,
								response.data.indexOf('&', ludocidIndex)
							);
						}

						// --------- requested by John: if it's aservice area, don't set lat and long, it will be done manually
						// let localMapData = responseJson.local_map || locationData.local_map;

						// if (localMapData && localMapData.gps_coordinates) {
						// 	tempLocation.lat = localMapData.gps_coordinates.latitude;
						// 	tempLocation.lng = localMapData.gps_coordinates.longitude;
						// }

						//working hours
						let hoursData = responseJson.hours || locationData.hours;
						if (hoursData) {
							if (hoursData.monday && hoursData.monday.opens && hoursData.monday.closes) {
								tempLocation.hrsMonday =
									convertAmPmTo24Hours(hoursData.monday.opens, true) +
									'-' +
									convertAmPmTo24Hours(hoursData.monday.closes, false);

								if (tempLocation.hrsMonday.indexOf('Closed') > -1) tempLocation.hrsMonday = null;
							}
							if (hoursData.tuesday && hoursData.tuesday.opens && hoursData.tuesday.closes) {
								tempLocation.hrsTuesday =
									convertAmPmTo24Hours(hoursData.tuesday.opens, true) +
									'-' +
									convertAmPmTo24Hours(hoursData.tuesday.closes, false);
								if (tempLocation.hrsTuesday.indexOf('Closed') > -1) tempLocation.hrsTuesday = null;
							}
							if (hoursData.wednesday && hoursData.wednesday.opens && hoursData.wednesday.closes) {
								tempLocation.hrsWednesday =
									convertAmPmTo24Hours(hoursData.wednesday.opens, true) +
									'-' +
									convertAmPmTo24Hours(hoursData.wednesday.closes, false);
								if (tempLocation.hrsWednesday.indexOf('Closed') > -1) tempLocation.hrsWednesday = null;
							}
							if (hoursData.thursday && hoursData.thursday.opens && hoursData.thursday.closes) {
								tempLocation.hrsThursday =
									convertAmPmTo24Hours(hoursData.thursday.opens, true) +
									'-' +
									convertAmPmTo24Hours(hoursData.thursday.closes, false);
								if (tempLocation.hrsThursday.indexOf('Closed') > -1) tempLocation.hrsThursday = null;
							}
							if (hoursData.friday && hoursData.friday.opens && hoursData.friday.closes) {
								tempLocation.hrsFriday =
									convertAmPmTo24Hours(hoursData.friday.opens, true) +
									'-' +
									convertAmPmTo24Hours(hoursData.friday.closes, false);
								if (tempLocation.hrsFriday.indexOf('Closed') > -1) tempLocation.hrsFriday = null;
							}
							if (hoursData.saturday && hoursData.saturday.opens && hoursData.saturday.closes) {
								tempLocation.hrsSaturday =
									convertAmPmTo24Hours(hoursData.saturday.opens, true) +
									'-' +
									convertAmPmTo24Hours(hoursData.saturday.closes, false);
								if (tempLocation.hrsSaturday.indexOf('Closed') > -1) tempLocation.hrsSaturday = null;
							}
							if (hoursData.sunday && hoursData.sunday.opens && hoursData.sunday.closes) {
								tempLocation.hrsSunday =
									convertAmPmTo24Hours(hoursData.sunday.opens, true) +
									'-' +
									convertAmPmTo24Hours(hoursData.sunday.closes, false);
								if (tempLocation.hrsSunday.indexOf('Closed') > -1) tempLocation.hrsSunday = null;
							}
						}
					}

					setSelectedBusiness(tempLocation);
				}
			}

			setIsLoading(false);
		}
	};

	const handleMapUrlLookup = async (e) => {
		e.preventDefault();

		if (!shareMapUrl || shareMapUrl.trim === '' || !shareMapUrl.startsWith('https://maps.app.goo.gl')) {
			toast.error('Invalid Google Maps share URL');
			setShareMapUrl();
			return;
		}

		setIsLoading(true);

		var response = await FindGoogleProfileInformation(shareMapUrl);

		if (response.success) {
			let placeId = response.placeId;
			let cid = response.cid;

			let tempLocation = {};
			tempLocation.googleBusinessUrl = shareMapUrl;
			tempLocation.place_id = placeId;
			tempLocation.cid = cid;

			if (user) {
				if (user.isAdmin) {
					tempLocation.userId = selectedUser && selectedUser.value !== '0' ? selectedUser.value : '';
				} else {
					tempLocation.userId = user.id;
				}
			}

			if (approvedOrder && approvedOrder.userId) {
				tempLocation.userId = approvedOrder.userId;
			}

			var response = await SerpFindGoogleProfileByPlaceId(placeId);

			if (response.success && response.data) {
				let responseJson = JSON.parse(response.data);

				if (responseJson && responseJson.place_results) {
					tempLocation.name = responseJson.place_results.title || '';
					tempLocation.website = responseJson.place_results.website || '';
					tempLocation.formatted_phone_number = responseJson.place_results.phone || '';

					//working hours
					let hoursData = responseJson.place_results.hours;
					if (hoursData && hoursData.length > 0) {
						hoursData.forEach((hoursDataItem) => {
							daysInWeek.forEach((day) => {
								if (hoursDataItem[day.toLowerCase()]) {
									if (hoursDataItem[day.toLowerCase()].indexOf('Open 24 hours') > -1)
										tempLocation[`hrs${day}`] = '00:00-23:59';
									else if (hoursDataItem[day.toLowerCase()].indexOf('Closed') > -1)
										tempLocation[`hrs${day}`] = null;
									else {
										let hoursArgs = hoursDataItem[day.toLowerCase()].split('–');

										if (hoursArgs && hoursArgs.length === 2) {
											tempLocation[`hrs${day}`] =
												convertAmPmTo24Hours(hoursArgs[0].trim(), true) +
												'-' +
												convertAmPmTo24Hours(hoursArgs[1].trim(), false);
										}
									}
								}
							});
						});
					}
				}
			}

			setSelectedBusiness(tempLocation);
		} else {
			if (response.errorMessage === 'Place ID not found' || response.errorMessage === 'CID not found') {
				toast.error(response.errorMessage + '. Please, try again');
			} else {
				toast.error(response.errorMessage);
				setShareMapUrl();
			}
		}

		setIsLoading(false);
	};

	const handleManualCampaign = async () => {
		//register user
		let userId, email, firstName, lastName;

		if (campaignId) {
			var campaign = GetCampaign(campaignId);

			if (campaign) {
				userId = campaign.userId;
			}
		} else if (user) {
			if (user.isAdmin) {
				// let selectedUserValue = selectedUserRef.current ? selectedUserRef.current.getValue() : null;
				// userId =
				// 	selectedUserValue && selectedUserValue.length && selectedUserValue[0].value !== '0'
				// 		? selectedUserValue[0].value
				// 		: null;

				userId = selectedUser && selectedUser.value !== '0' ? selectedUser.value : '';
			} else {
				userId = user.id;
			}
		}

		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		if (!userId) {
			email = name.toLowerCase().replace(/ /g, '').replace(/[^\w-]+/g, '') + '@realwebsite.com';

			let nameArgs = name.split(' ');
			if (nameArgs && nameArgs.length > 1) {
				firstName = nameArgs[0];
				lastName = name.replace(firstName, '').trim();
			} else {
				firstName = name;
			}

			let userData = {
				email     : email,
				password  : 'pa$$w0rd',
				firstName : firstName,
				lastName  : lastName,
				status    : 'Active'
			};

			let userResponse = await RegisterUser(userData);

			if (userResponse && userResponse.id) {
				userId = userResponse.id;
			} else if (userResponse && !userResponse.IsSuccessful) {
				if (
					userResponse.Message &&
					userResponse.Message.response &&
					userResponse.Message.response.data &&
					userResponse.Message.response.data.length
				) {
					let errorMessage = userResponse.Message.response.data[0];

					console.log('error: ' + errorMessage);
					toast.error(errorMessage);
				}
			}
		}

		if (userId) {
			//create campaign
			let campaignData = {
				userId : userId,
				name   : name
			};

			let campaignResponse;

			if (campaignId) {
				campaignResponse = await UpdateCampaign(campaignId, campaignData);
			} else {
				campaignResponse = await CreateCampaign(campaignData);
			}

			if (campaignResponse && campaignResponse.id) {
				//redirect to campaign update screen
				navigate(`/setup/${campaignResponse.id}`);
			}
		}
	};

	const getSerpApiGooglePlaceReviews = async (placeId) => {
		let reviews = [];

		if (placeId) {
			const response = await GetGooglePlaceReviews(placeId);

			if (response && response.success && response.reviews) {
				for (let i = 0; i < response.reviews.length; i++) {
					reviews.push(response.reviews[i]);
				}
			}
		}

		return reviews;
	};

	const handleCreateSalesCompany = async () => {
		if (user && user.isSalesUser) {
			let isValid = document.querySelector('form').reportValidity();

			if (!isValid) {
				return;
			}

			let email = name.toLowerCase().replace(/ /g, '').replace(/[^\w-]+/g, '') + '@realwebsite.com';

			//create sales company
			let salesCompanyData = {
				userId            : user.id,
				name              : name,
				email             : email,
				googleCid         : googleCid,
				googlePlaceId     : googlePlaceId,
				googleBusinessUrl : googleCid ? 'https://maps.google.com/?cid=' + googleCid : '',
				// googleBusinessUrl : selectedBusiness.googleBusinessUrl
				// 	? selectedBusiness.googleBusinessUrl
				// 	: selectedBusiness.cid ? 'https://maps.google.com/?cid=' + selectedBusiness.cid : '',
				lat               : lat,
				lng               : lng,
				status            : 'Active'
			};

			const response = await CreateSalesCompany(salesCompanyData);

			if (response && response.success) {
				RemoveSalesBusiness();

				if (response.data && response.data.id) {
					//set temp task data
					StoreSalesTask({
						companyId : response.data.id,
						lat       : lat,
						lng       : lng
					});

					navigate('/sales/' + response.data.id + '/tasks');
				}
			} else {
				toast.error(response.message);
			}
		}
	};

	const convertAmPmTo24Hours = (hoursValue, isOpen) => {
		let hours24;

		if (hoursValue.trim().indexOf('am') > -1 || hoursValue.trim().indexOf('AM') > -1) {
			hours24 = hoursValue.replace('am', '').replace('AM', '').trim();

			//add leading zero
			hours24 = parseInt(hours24);
			if (hours24 < 10) {
				hours24 = '0' + hours24.toString();
			}
		} else if (hoursValue.trim().indexOf('pm') > -1 || hoursValue.trim().indexOf('PM') > -1) {
			hours24 = hoursValue.replace('pm', '').replace('PM', '').trim();
			hours24 = parseInt(hours24) + 12;
		} else if (hoursValue.indexOf('Open 24 hours') > -1) {
			if (isOpen) {
				hours24 = '00:00';
			} else {
				hours24 = '23:59';
			}
		} else {
			hours24 = hoursValue;
		}

		if (hours24.toString().indexOf(':') === -1) {
			hours24 = hours24 + ':00';
		}

		return hours24;
	};

	return (
		<Fragment>
			{!approvedOrder && (
				<div className="container-fluid p-3">
					<div className="row">
						<div className="col-12">
							<PageHeadline headline="New Campaign" linkText="Campaigns" linkUrl="/campaigns/list" />
						</div>
					</div>
				</div>
			)}

			<CenterContainer inSidebar={approvedOrder}>
				<div className={`gbp-selector-content text-center ${approvedOrder ? 'p-0' : ''}`}>
					{isLoading ? (
						<Spinner />
					) : (
						<Fragment>
							{!approvedOrderConfirmSelection && (
								<div>
									{user &&
									(user.isAdmin || user.isSalesUser) &&
									!approvedOrder && (
										<div className="mb-4 text-start">
											{activeUsers &&
											selectedUser && (
												<Select
													options={activeUsers}
													value={selectedUser}
													onChange={(e) => {
														setSelectedUser(e);
													}}
													required
													ref={selectedUserRef}
												/>
											)}
										</div>
									)}

									{!manuallyCreate && (
										<Fragment>
											<GoogleBusinessAutoComplete
												onPlaceSelected={handlePlaceSelected}
												defaultPlace={approvedOrder ? approvedOrder.streetAddress1 : null}
											/>

											<div className="my-2">or</div>

											{/* <div className="input-group">
												<input
													type="text"
													className="form-control"
													placeholder="Enter Service Area Business"
													onChange={(e) => setSearchParam(e.target.value)}
												/>
												<button className="btn btn-primary" onClick={handlePlaceIdSelect}>
													Go
												</button>
											</div> */}

											<div className="input-group">
												<input
													type="text"
													className="form-control"
													placeholder="Enter Share Map Url"
													value={shareMapUrl || ''}
													onChange={(e) => setShareMapUrl(e.target.value)}
												/>
												<button className="btn btn-primary" onClick={handleMapUrlLookup}>
													Go
												</button>
											</div>

											{/* <div>
												<a
													className="btn btn-link mt-2"
													target="_blank"
													href="https://developers-dot-devsite-v2-prod.appspot.com/my-business/content/tools/placeid-lookup"
												>
													Service Area Lookup Tool
												</a>
											</div> */}

											<div id="map" style={{ display: 'none' }} />

											{!approvedOrder && (
												<div className="mt-2 mb-2">
													<span>or</span>
													<button
														className="btn btn-link p-0 ms-2"
														onClick={() => setManuallyCreate(true)}
													>
														manually create
													</button>
												</div>
											)}
										</Fragment>
									)}

									{manuallyCreate && (
										<Fragment>
											<form>
												<div className="row mb-3 text-start">
													<label htmlFor="name" className="form-label mt-1 col-2">
														Name:
													</label>
													<div className="col-10">
														<input
															type="text"
															className="form-control"
															name="name"
															onChange={(e) => setName(e.target.value)}
															required
														/>
													</div>
												</div>
												{isSalesProcess && (
													<Fragment>
														<div className="row mb-3 text-start">
															<label
																htmlFor="googlePlaceId"
																className="form-label mt-1 col-2"
															>
																Google Place Id:
															</label>
															<div className="col-10">
																<input
																	type="text"
																	className="form-control"
																	name="googlePlaceId"
																	onChange={(e) => setGooglePlaceId(e.target.value)}
																	required
																/>
																<div>
																	<a
																		className="btn btn-link p-0"
																		target="_blank"
																		href="https://developers-dot-devsite-v2-prod.appspot.com/my-business/content/tools/placeid-lookup"
																	>
																		Service Area Lookup Tool
																	</a>
																</div>
															</div>
														</div>
														<div className="row mb-3 text-start">
															<label
																htmlFor="googleCid"
																className="form-label mt-1 col-2"
															>
																Google Cid:
															</label>
															<div className="col-10">
																<input
																	type="text"
																	className="form-control"
																	name="googleCid"
																	onChange={(e) => setGoogleCid(e.target.value)}
																	required
																/>
															</div>
														</div>
														<div className="row mb-3 text-start">
															<label htmlFor="lat" className="form-label mt-1 col-2">
																<span>Center Point:</span>
															</label>
															<div className="col-10">
																<div className="input-group">
																	<input
																		type="text"
																		className="form-control rounded"
																		name="lat"
																		onChange={(e) => setLat(e.target.value)}
																		required
																	/>
																	<input
																		type="text"
																		className="form-control rounded ms-2"
																		name="lng"
																		onChange={(e) => setLng(e.target.value)}
																		required
																	/>
																</div>
																<div>
																	<a
																		className="btn btn-link p-0"
																		target="_blank"
																		href={`https://maps.google.com/?cid=${googleCid}`}
																	>
																		Google Business Profile
																	</a>
																</div>
															</div>
														</div>
													</Fragment>
												)}
												<div>
													<div className="col-12">
														<button
															className="btn btn-primary"
															type="button"
															onClick={
																isSalesProcess ? (
																	handleCreateSalesCompany
																) : (
																	handleManualCampaign
																)
															}
														>
															Save
														</button>
														<button
															className="btn btn-outline-primary ms-2"
															type="button"
															onClick={() => setManuallyCreate(false)}
														>
															Go Back
														</button>
													</div>
												</div>
											</form>
										</Fragment>
									)}
								</div>
							)}

							{approvedOrderConfirmSelection &&
							selectedBusiness && <ConfirmBusinessSelectionScreen approvedOrder={approvedOrder} />}
						</Fragment>
					)}
				</div>
			</CenterContainer>
		</Fragment>
	);
};

export default GbpSelectScreen;
